import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "../../../components/SliderBanner.css";
import Swal from "sweetalert2";

export default function SportsShopManagement() {
  const [vendors, setVendors] = useState([]);
   const [categories, setCategories] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    about: "",
    vendorURL: "",
    vendorImage: "",
    registerDate: "",
    expireDate: "",
  });


  // Fetch vendors from backend API
  useEffect(() => {
    axios.get("/api/shopvendor")
      .then((response) => setVendors(response.data))
      .catch((error) => console.error("Error fetching vendors:", error));
  }, []);

  // Fetch categories from get_vender API
  const fetchCategories = async () => {
    try {
      const response = await axios.get("/api/get_vender");
      const allCategories = response.data || [];
      // Filter categories to include only Defence vendors
      const defenceCategories = allCategories.filter(
        (category) => category.isPaid === "Paid" && category.category._id === "6784488118b352523a395609"
      );
      setCategories(defenceCategories);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const handleVendorSelect = (e) => {
    const vendorId = e.target.value;
    setSelectedVendorId(vendorId);

    const selectedVendor = vendors.find((ven) => ven._id === vendorId);
    if (selectedVendor) {
      setFormData({
        id: selectedVendor._id,
        name: selectedVendor.name,
        about: selectedVendor.about,
        vendorURL: selectedVendor.vendorURL,
        vendorImage: null,
        registerDate: selectedVendor.registerDate,
        expireDate: selectedVendor.expireDate,
      });
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    setFormData((prev) => ({
      ...prev,
      [name]:value
    }));
  };

  // Handle file input for image
  const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const validTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!validTypes.includes(file.type)) {
          Swal.fire("Error", "Please upload a valid image (JPEG, PNG)", "error");
          return;
        }
        if (file.size > 5 * 1024 * 1024) {
          Swal.fire("Error", "Image size must be less than 5MB", "error");
          return;
        }
        setFormData((prev) => ({
          ...prev,
          vendorImage: file,
        }));
      }
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      
      const form = new FormData();
      form.append("name", formData.name);
      form.append("vendorURL", formData.vendorURL); // Use vendorURL as-is
      form.append("about", formData.about);
      form.append("expireDate", formData.expireDate);
      form.append("registerDate", formData.registerDate);
      if (formData.vendorImage) form.append("vendorImage", formData.vendorImage);
    
      try {
        // Update existing vendor
        await axios.put(`/api/shopvendor/${formData.id}`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        Swal.fire("Success", "Vendor updated successfully!", "success");
    
        // Reset form and refresh vendors
        setFormData({
          id: "",
          name: "",
          about: "",
          vendorURL: "",
          vendorImage: null,
          registerDate: "",
          expireDate: "",
        });
    
        // Re-fetch vendors after updating
        axios
          .get("/api/shopvendor")
          .then((response) => setVendors(response.data))
          .catch((error) => console.error("Error fetching vendors:", error));
    
      } catch (error) {
        console.error("Error submitting vendor:", error.response?.data || error.message);
        Swal.fire("Error", "Failed to update vendor.", "error");
      }
    };
 
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024, // For tablet screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For mobile screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <>

      <div className="container">
        {/* Slider Section */}
        <div className="slider-container">
          <div className="slider-title px-3 mb-3">
            <h2>Sports Shop</h2>
          </div>
          <Slider className="myslider" {...settings}>
            {vendors.map((vendor, index) => (
              <div className="card" key={vendor._id}>
                <div className="card-number">
                </div>

                <img
                  src={`https://api.khiladiindia.com/backend/uploads/ShopImage/${vendor.vendorImage}`}
                  alt={vendor.name}
                  className="card-image"
                />
                <div className="card-content">
                  <h3 className="card-title">{vendor.name}</h3>
                  <p className="card-description">{vendor.about}</p>
                  <Link
                    to={`/Sports-Shop/${vendor.vendorURL}`}
                    className="col-lg-6"
                    key={vendor._id}
                  >
                    Know More
                  </Link>
                </div>
              </div>
            ))}
          </Slider>

        </div>

        {/* Vendor Form */}
        <div className="row">
          <div className="col-md-3">
            <div className="form-group my-4">
              <label>Select Vendor</label>
              <select
                className="form-control"
                value={selectedVendorId}
                onChange={handleVendorSelect}
              >
                <option value="">Select a vendor</option>
                {vendors.map((vendor) => (
                  <option key={vendor._id} value={vendor._id}>
                    {vendor.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="mb-5">
            <h3>Edit Vendor</h3>
            <form className="row" onSubmit={handleSubmit}>
              <div className="col-md-2 mb-3">
                <label>Vendor Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Enter vendor name"
                  className="form-control"
                />
              </div>
              <div className="col-md-2 mb-3">
                <label>About</label>
                <input
                  type="text"
                  name="about"
                  value={formData.about}
                  onChange={handleInputChange}
                  placeholder="About"
                  className="form-control"
                />
              </div>
              <div className="col-md-2 mb-3">
                <label>Vendor Profile Image</label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="form-control"
                  name="vendorImage"
                />
              </div>
              <div className="col-md-2 mb-3">
                <label>Vendor URL</label>
                <select
                  name="vendorURL"
                  value={formData.vendorURL}
                  onChange={handleInputChange}
                  className="form-control"
                >
                  <option value="">Select Defence Vendor</option>
                  {categories.map((cat) => (
                    <option key={cat._id} value={cat.title}>
                      {`${cat._id} - ${cat.title}`} {/* Display the appropriate field for vendor title */}
                    </option>
                  ))}
                </select>

              </div>
              <div className="col-md-2 mb-3">
                <label>Register Date:</label>
                <input
                  type="date"
                  name="registerDate"
                  value={formData.registerDate}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>
              <div className="col-md-2 mb-3">
                <label>Expire Date:</label>
                <input
                  type="date"
                  name="expireDate"
                  value={formData.expireDate}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <button type="submit" className="btn btn-primary">
                  Update Vendor
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
