import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AiOutlineClose, AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import khiladilogo from "../assets/khiladilogo.png";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Invalid email format.");
      return;
    }

    try {
      const response = await axios.post("/api/forgot_password", { email });
      setMessage(response.data.msg);
      setStep(2);
    } catch (err) {
      setError(err.response?.data?.msg || "Failed to send OTP. Please try again.");
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
  
    try {
      const response = await axios.post("/api/verify-otp", { email, otp });
      setMessage(response.data.msg);
      setStep(3);
    } catch (err) {
      setError(err.response?.data?.msg || "Invalid OTP. Please try again.");
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
  
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
  
    try {
      const response = await axios.post("/api/reset-password", { email, otp, newPassword });
      setMessage(response.data.msg);
      navigate("/login");
    } catch (err) {
      setError(err.response?.data?.msg || "Failed to reset password. Please try again.");
    }
  };

  const handleClose = () => {
    navigate("/");
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <div>
      <div className="modal1">
        <div className="login-modal mt-0" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content1">
            <div className="d-flex headerlogin mb-4 align-items-center p-2">
              <img src={khiladilogo} alt="Logo" className="logo-leftforgot" />
              <h2 className="login-title">Forgot Password</h2>
              <button className="close-buttonLogin" onClick={handleClose}>
                <AiOutlineClose size={20} />
              </button>
            </div>
            {message && <p className="text-green-600 mb-4 mt-4 text-center">{message}</p>}
            {error && <p className="text-red-600 mt-3 mb-3">{error}</p>}
            
            {step === 1 && (
              <form onSubmit={handleSendOtp}>
                <div className="form-group">
                  <input
                    type="email"
                    value={email}
                    placeholder="Enter Email*"
                    className="form-control font-weight-bold"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="text-center resetpasswordbutton rounded">Send OTP</button>
              </form>
            )}
            
            {step === 2 && (
              <form onSubmit={handleVerifyOtp}>
                <div className="form-group">
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP*"
                    className="form-control font-weight-bold"
                    required
                  />
                </div>
                <button type="submit" className="text-center resetpasswordbutton rounded">Verify OTP</button>
              </form>
            )}

            {step === 3 && (
              <form onSubmit={handleResetPassword}>
                <div className="form-group position-relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter New Password*"
                    className="form-control font-weight-bold"
                    required
                  />
                  <span className="position-absolute top-50 end-0 translate-middle-y me-2" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                  </span>
                </div>
                <div className="form-group position-relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm New Password*"
                    className="form-control font-weight-bold"
                    required
                  />
                  <span className="position-absolute top-50 end-0 translate-middle-y me-2" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                  </span>
                </div>
                <button type="submit" className="text-center resetpasswordbutton rounded">Reset Password</button>
                <div className="mt-3 text-center d-flex justify-content-center">
                  <p>Or</p>
                  <Link to="/" className="fotgotpassword ms-3">Login</Link>
                </div>
              </form>
            )}

            {step > 1 && (
              <button onClick={handleBack} className="back-button rounded btn btn-secondary">Back</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
