import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import "./dashboard.css";

function Dashboard() {
    const [isSidebarActive, setSidebarActive] = useState(false);

    return (
        <div className="wrapper w-100 h-100vh" style={{ paddingTop: "-100px !important", overflowY: 'hidden' }}>
            {/* Sidebar for larger screens */}
            <Sidebar isSidebarActive={isSidebarActive} />
            
            {/* Sidebar for mobile */}
            <div className="mobile shadow-lg">
                <Sidebar isSidebarActive={isSidebarActive} />
            </div>

            {/* Main content */}
            <div className="main dashboard_main">
                <Header setSidebarActive={setSidebarActive} />
                <Outlet />
            </div>
        </div>
    );
}

export default Dashboard;
