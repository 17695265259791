import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Button } from 'react-bootstrap';
import { FaPhone, FaMapMarkerAlt, FaClock, FaEnvelope, FaShare, FaWhatsapp, FaFacebook, FaEnvelopeOpen, FaTwitter, FaLinkedin, } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function VenderPage1() {
  const { title } = useParams(); // Only retrieve title from URL
  const [vendorData, setVendorData] = useState(null);

  // Fetch vendor data from an API
  const fetchSingleVendorData = () => {
    axios.get(`/api/get_vender/${title}`)
      .then(response => {
        console.log(response.data.data)
        setVendorData(response.data.data);  // Set the fetched vendor data
      })
      .catch(error => {
        console.error('Error fetching vendor data:', error);
      });
  };

  useEffect(() => {
    fetchSingleVendorData();
  }, [title]);

  if (!vendorData) return <div>Loading...</div>;  // Show loading until data is fetched

  return (
    <VendorCard1 data1={vendorData} />
  );
}

function VendorCard1({ data1 }) {
  const handlePhoneClick = () => {
    window.location.href = "tel:" + data1.phone;  // Initiates a phone call
  };

  const handleWhatsAppClick = () => {
    if (data1 && data1.phone) {
      window.open(`https://wa.me/${data1.phone}`, "_blank");  // Redirect to WhatsApp with the number
    } else {
      alert("Phone number is not available.");
    }
  };

  const handleCopyUrl = () => {
    const shareUrl = window.location.href;
    navigator.clipboard.writeText(shareUrl)
      .then(() => {
        console.log("URL copied to clipboard:", shareUrl);
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy URL:", err);
      });
  };

  const handleWhatsAppShare = () => {
    const shareText = `Check out ${data1.title}: ${window.location.href}`;
    window.open(`https://wa.me/?text=${encodeURIComponent(shareText)}`, "_blank");
  };

  const handleFacebookShare = () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
    window.open(shareUrl, "_blank");
  };

  const handleEmailShare = () => {
    const subject = `Check out ${data1.title}`;
    const body = `I found this interesting: ${window.location.href}`;
    window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const handleLinkedInShare = () => {
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}`;
    window.open(linkedInUrl, "_blank");
  };

  const handleTwitterShare = () => {
    const tweetText = `Check out ${data1.title}: ${window.location.href}`;
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`, "_blank");
  };


  const handleGetDirections = () => {
    window.location.href = data1.mapLocation;
  };

  if (!data1) return null;


  return (
    <div style={styles.container}>
      <div className="VenderBanner">
        {data1.bannerImage ? (
          <img className=""
            src={`https://api.khiladiindia.com/backend/uploads/bannerImage/${data1.bannerImage.split('/').pop()}`}
            alt="banner Image"
            width="50"
            height="50"
            style={{ objectFit: 'cover' }}
          />
        ) : (
          "NA"
        )}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 mt-2">
            <div className="mt-2" style={styles.heading}>
              <h2>{data1.title}</h2>
            </div>
          </div>
          <div className="col-md-12" style={styles.ratingContainer}>
            <div className="d-flex gap-3 align-items-center">
              <div className="d-flex mb-0">
                <strong className='mb-0'>
                  <i style={styles.medalicon} className="fa-solid fa-medal mt-1"></i>
                  {data1.award}
                </strong>
              </div>
              <p className='text-gray mb-0'><span style={styles.openattext}>Opens at </span> {data1.timings}</p>
              <div className="">
                <p className='text-gray fw-500 mb-0' style={styles.yearinsport}>{data1.yearsExperience} years in Sports</p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="col-md-12">
              <div className="d-flex gap-3 mt-3" style={styles.buttonsWrapper}>
                <button style={styles.buttonGreen} onClick={handlePhoneClick}>
                  <i className="fa-solid fa-phone me-2"></i>{data1.phone}
                </button>
                <button style={styles.buttonWhite} onClick={handleWhatsAppClick}>
                  <i style={styles.whatsappIcon} className="fa-brands fa-square-whatsapp me-2 fs-4"></i>WhatsApp
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-md-9 col-12 order-1 order-md-1">
            <Tabs defaultActiveKey="About" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="About" title="About">
                <div className="about-section row">
                  <div className="video-container col-md-6 col-12">
                    {data1.businessUrl ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: data1.businessUrl }}
                        style={{ width: '100%', height: '315px' }}
                      />
                    ) : (
                      <p>No video available</p>
                    )}
                  </div>
                  <div className="about-text ms-3 col-md-6 col-12">
                    <h3>{data1.title}</h3>
                    <p>
                      {data1.about}
                    </p>
                  </div>
                </div>
              </Tab>


              <Tab eventKey="Catalogue" title="Catalogue">
                <div className="row row-gap-2">
                  {[...Array(12)].map((_, index) => {
                    const catelogueKey = `catelogue${index + 1}`;
                    const headingKey = `heading${index + 1}`;

                    return (
                      <div key={index} className="col-6 col-md-3 text-center">
                        {data1[catelogueKey] && data1[catelogueKey] !== "" ? (
                          <div className="productContainer border rounded p-2 mb-3">
                            <img
                              src={`https://api.khiladiindia.com/backend/uploads/catelogues/${data1.catelogue1}`}
                              className="rounded catelogueimg"
                              height="100px"
                              alt={`Catalog ${index + 1}`}
                            />
                            <h6 className="py-2">{data1[headingKey]}</h6>
                            <a
                              href={`https://wa.me/${data1.phone}?text=Hello,%20I%20am%20interested%20in%20your%20product%20\"${data1[headingKey]}\".`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-outline-primary btn-sm"
                            >
                              Ask For Price
                            </a>
                          </div>
                        ) : (
                          <div className="productContainer border rounded p-2 mb-3">
                            <p>No Catalogue Available</p>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Tab>


            </Tabs>
          </div>

          <div className="col-md-3 col-12 order-2 order-md-2 mt-4 mt-md-0" style={styles.rightColumn}>
            <h3 style={styles.heading}>Contact</h3>
            <div style={styles.contactDetails}>
              <p><FaPhone style={styles.iconStyle} />{data1.phone}</p>
              <p><FaEnvelope style={styles.iconStyle} />{data1.email}</p>
              <p><FaMapMarkerAlt style={styles.iconStyle} />{data1.address}</p>
              {data1.mapLocation ? (
                <div style={{ width: '100%', height: '300px', overflow: 'hidden' }} className='mb-2'>
                  <iframe
                    src={`https://www.google.com/maps/embed?pb=${data1.mapLocation}`}
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              ) : (
                <p>No map location provided</p>
              )}
              <Button variant="primary" className="mb-3" onClick={handleGetDirections}>
                Get Directions
              </Button>
              <p><FaClock style={styles.iconStyle} />{data1.timings}</p>
              <p className='text-center fw-bold'>Share Profile</p>
              <div className="d-flex gap-2 align-items-center">
                <button onClick={handleCopyUrl} className="btn btn-outline-primary">
                  <FaShare />
                </button>
                <button onClick={handleWhatsAppShare} className="btn btn-outline-success">
                  <FaWhatsapp />
                </button>
                <button onClick={handleFacebookShare} className="btn btn-outline-primary">
                  <FaFacebook />
                </button>
                <button onClick={handleEmailShare} className="btn btn-outline-secondary">
                  <FaEnvelopeOpen />
                </button>
                <button onClick={handleTwitterShare} className="btn btn-outline-info">
                  <FaTwitter />
                </button>
                <button onClick={handleLinkedInShare} className="btn btn-outline-secondary">
                  <FaLinkedin />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: '20px',
  },
  heading: {
    textAlign: 'left',
  },
  buttonGreen: {
    color: 'white',
    backgroundColor: 'green',
    borderRadius: '8px',
    padding: '5px 10px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  buttonWhite: {
    color: 'black',
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '5px 10px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  whatsappIcon: {
    backgroundColor: 'white',
    borderRadius: '8px',
    fontSize: '20px',
    color: 'green',
  },
  openattext: {
    fontWeight: '700',
    color: 'green',
  },
  ratingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  stars: {
    display: 'flex',
    gap: '5px',
    cursor: 'pointer',
    fontSize: '30px',
  },
  star: {
    padding: '7px',
    borderRadius: '8px',
    fontSize: '35px',
  },
  ratetext: {
    textAlign: 'center',
  },
  buttonsWrapper: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  rightColumn: {
    paddingLeft: '20px',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'left',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  headingContact: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  contactDetails: {
    lineHeight: '1.8',
  },
  iconStyle: {
    marginRight: '8px',
    color: '#1a73e8',
  },
  yearinsport: {
    fontWeight: 'bold',
    color: '#2998c1',
  },
  medalicon: {
    color: 'red',
  },
};
