import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const SignUpFreeVendor = () => {
    const [vendorCat, setVendorCat] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [vendorData, setVendorData] = useState({
        title: "",
        phone: "",
        email: "",
        category: "",
        password: "",
        confirmPassword: "",
        mapLocation: "",
        profileUrl: null,
    });
    const [isFormValid, setIsFormValid] = useState(true);
    const [successMessage, setSuccessMessage] = useState("");

    // Handle change in input fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setVendorData((prev) => ({ ...prev, [name]: value }));
    };

    // Handle file change for profile image
    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setVendorData((prev) => ({ ...prev, profileUrl: e.target.files[0] }));
        }
    };

    // Form validation logic
    const validateForm = () => {
        const { title, phone, email, category, password, confirmPassword, mapLocation, profileUrl } = vendorData;
        if (
            !title || !phone || !email || !category || !password || !confirmPassword || !mapLocation || !profileUrl
        ) {
            return false;
        }
        if (password !== confirmPassword) {
            return false;
        }
        if (!/^\d{10}$/.test(phone)) {
            return false;
        }
        return true;
    };

    // Submit form data to create vendor and handle token
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            setIsFormValid(false);
            return;
        }

        setIsFormValid(true);
        let formData;
        const isMultipart = vendorData.profileUrl !== null;

        if (isMultipart) {
            formData = new FormData();
            Object.entries(vendorData).forEach(([key, value]) => {
                if (key === "profileUrl") {
                    formData.append(key, value);
                } else {
                    formData.append(key, value);
                }
            });
        } else {
            formData = { ...vendorData };
        }

        try {
            const response = await axios.post("/api/add_vender", formData, {
                headers: isMultipart
                    ? { "Content-Type": "multipart/form-data" }
                    : { "Content-Type": "application/json" },
            });
            const token = response.data.token;
            localStorage.setItem("vendorToken", token);
            setSuccessMessage("Registration successful! Please check your email to activate your account.");
            setVendorData({
                title: "",
                phone: "",
                email: "",
                category: "",
                password: "",
                confirmPassword: "",
                mapLocation: "",
                profileUrl: null,
            });
        } catch (error) {
            console.error("Error:", error.response?.data || error.message);
            Swal.fire("Error!", "Failed to add vendor.", "error");
        }
    };

    useEffect(() => {
        axios.get('/api/get-all-category')
            .then((response) => {
                setVendorCat(response.data);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });
    }, []);

    return (
        <div className="container my-5">
            <h3 className="ms-5">Register Your Business</h3>
            <form onSubmit={handleSubmit} className="row">
                <div className="mb-3 col-md-6">
                    <label className="form-label">Business Name *</label>
                    <input
                        type="text"
                        className="form-control"
                        name="title"
                        onChange={handleChange}
                        value={vendorData.title}
                        required
                    />
                </div>

                <div className="mb-3 col-md-6">
                    <label className="form-label">Mobile Number *</label>
                    <input
                        type="text"
                        className="form-control"
                        name="phone"
                        maxLength="10"
                        pattern="\d{10}"
                        onChange={handleChange}
                        value={vendorData.phone}
                        required
                    />
                </div>

                <div className="mb-3 col-md-6">
                    <label className="form-label">Email *</label>
                    <input
                        type="email"
                        className="form-control"
                        name="email"
                        onChange={handleChange}
                        value={vendorData.email}
                        required
                    />
                </div>

                <div className="mb-3 col-md-6">
                    <label className="form-label">Category *</label>
                    <select
                        className="form-control"
                        name="category"
                        onChange={handleChange}
                        value={vendorData.category}
                        required
                    >
                        <option value="">Select Category *</option>
                        {vendorCat.map((cat) => (
                            <option key={cat._id} value={cat._id}>{cat.category}</option>
                        ))}
                    </select>
                </div>

                <div className="mb-3 col-md-6">
                    <label className="form-label">Password *</label>
                    <div className="position-relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            name="password"
                            onChange={handleChange}
                            value={vendorData.password}
                            required
                        />
                        <span
                            className="position-absolute top-50 end-0 translate-middle-y me-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                    </div>
                </div>

                <div className="mb-3 col-md-6">
                    <label className="form-label">Confirm Password *</label>
                    <div className="position-relative">
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control"
                            name="confirmPassword"
                            onChange={handleChange}
                            value={vendorData.confirmPassword}
                            required
                        />
                        <span
                            className="position-absolute top-50 end-0 translate-middle-y me-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                    </div>
                </div>


                <div className="mb-3 col-md-6">
                    <label className="form-label">Map Location *</label>
                    <input
                        type="text"
                        className="form-control"
                        name="mapLocation"
                        onChange={handleChange}
                        value={vendorData.mapLocation}
                        required
                    />
                </div>

                <div className="mb-3 col-md-6">
                    <label className="form-label">Profile Image *</label>
                    <input
                        type="file"
                        className="form-control"
                        name="profileUrl"
                        onChange={handleFileChange}
                        accept="image/*"
                        required
                    />
                </div>

                <button type="submit" className="postjobbutton m-auto me-0">
                    <span>Submit</span>
                </button>

                {!isFormValid && (
                    <div className="alert alert-danger mt-3">
                        Please make sure all fields are filled out correctly.
                    </div>
                )}
            </form>

            {successMessage && (
                <div className="alert alert-success mt-3">
                    {successMessage}
                </div>
            )}
        </div>
    );
};

export default SignUpFreeVendor;
