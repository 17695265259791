import React, { useState } from 'react';
import { RiDashboard3Line } from "react-icons/ri";
import { IoIosArrowForward, IoMdMenu } from "react-icons/io";
import { MdSportsBaseball } from "react-icons/md";
import { BiSolidCategory } from "react-icons/bi";
import { FcApproval } from "react-icons/fc";
import { SiHomepage } from "react-icons/si";
import { IoLogOut, IoSettingsSharp } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import homeIcon from '../../assets/home-icons.png'

function Sidebar({ isSidebarActive }) {
    const [activeDropdown, setActiveDropdown] = useState(null);
    const navigate = useNavigate();

    const handleDropdownToggle = (index) => {
        setActiveDropdown(activeDropdown === index ? null : index);
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        sessionStorage.clear();
        navigate('/khiladiindia');
    };

    const isUserLoggedIn = localStorage.getItem("user");
    const scond = JSON.parse(isUserLoggedIn);

    return (
        <aside id="sidebar" className={`js-sidebar ${isSidebarActive ? 'active' : ''}`}>

            <div className="h-100">
                <div className="sidebar-logo d-flex justify-content-between align-items-center">
                    <Link to="/">Khiladi India</Link>
                    <div className="homenavbar-icon mt-2 ms-4">
                        <Link to="/" style={{ textDecoration: 'none' }} className='homebuttonNav1 text-white fs-12'>
                            home
                        </Link>
                    </div>
                </div>

                {scond.data.userrollid === "admin" ?
                    <>
                        <ul className="sidebar-nav">
                            <li className="sidebar-item">
                                <Link to="/dashboard" className="sidebar-link">
                                    <RiDashboard3Line /> Dashboard
                                </Link>
                            </li>

                            <li className={`sidebar-item ${activeDropdown === 1 ? 'active' : ''}`}>
                                <Link
                                    to="#"
                                    className="sidebar-link"
                                    onClick={() => handleDropdownToggle(1)}
                                >
                                    <FaUsers /> Vender Management <span> <IoIosArrowForward /></span>
                                </Link>
                                <ul className={`sidebar-dropdown ${activeDropdown === 1 ? 'active' : ''}`}>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/total-users" className="sidebar-link">Total Venders</Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/add-user" className="sidebar-link">Add New Venders</Link>
                                    </li>
                                </ul>
                            </li>

                            <li className={`sidebar-item ${activeDropdown === 2 ? 'active' : ''}`}>
                                <Link
                                    to="#"
                                    className="sidebar-link"
                                    onClick={() => handleDropdownToggle(2)}
                                >
                                    <SiHomepage />  HomePage Management<span><IoIosArrowForward /></span>
                                </Link>
                                <ul className={`sidebar-dropdown ${activeDropdown === 2 ? 'active' : ''}`}>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/HomeBanner-Management" className="sidebar-link">Home Banner</Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/total-category" className="sidebar-link">Total Categories</Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/add-category" className="sidebar-link">Add New Category</Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/Difence-training-home" className="sidebar-link">Difence Training Centre</Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/Shop-Management" className="sidebar-link">Sports Shop Management</Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/Fitness-trainer-home" className="sidebar-link">Fitness Trainer Centre</Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/Sports-Academy" className="sidebar-link">Sports Academy Centre</Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/Kabaddi-Trainers" className="sidebar-link">Kabbadi Trainer Centre</Link>
                                    </li>
                                </ul>
                            </li>

                            <li className={`sidebar-item ${activeDropdown === 5 ? 'active' : ''}`}>
                                <Link
                                    to="#"
                                    className="sidebar-link"
                                    onClick={() => handleDropdownToggle(5)}
                                >
                                    <BiSolidCategory /> Categories<span> <IoIosArrowForward /></span>
                                </Link>
                                <ul className={`sidebar-dropdown ${activeDropdown === 5 ? 'active' : ''}`}>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/total-category" className="sidebar-link">Total Categories</Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/add-category" className="sidebar-link">Add New Category</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={`sidebar-item ${activeDropdown === 4 ? 'active' : ''}`}>
                                <Link
                                    to="#"
                                    className="sidebar-link"
                                    onClick={() => handleDropdownToggle(4)}
                                >
                                    <MdSportsBaseball /> Trainer Management<span> <IoIosArrowForward /></span>
                                </Link>
                                <ul className={`sidebar-dropdown ${activeDropdown === 4 ? 'active' : ''}`}>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/total-trainers" className="sidebar-link">Total Trainer</Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/trainer-add" className="sidebar-link">Add New Trainer</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={`sidebar-item ${activeDropdown === 3 ? 'active' : ''}`}>
                                <Link
                                    to="#"
                                    className="sidebar-link"
                                    onClick={() => handleDropdownToggle(3)}
                                >
                                    <BiSolidCategory />Trainer Categories<span> <IoIosArrowForward /></span>
                                </Link>
                                <ul className={`sidebar-dropdown ${activeDropdown === 3 ? 'active' : ''}`}>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/total-trainer-category" className="sidebar-link">Total Categories</Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/add-trainer-category" className="sidebar-link">Add New Category</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={`sidebar-item ${activeDropdown === 6 ? 'active' : ''}`}>
                                <Link
                                    to="#"
                                    className="sidebar-link"
                                    onClick={() => handleDropdownToggle(6)}
                                >
                                    <FcApproval />   Job Request<span> <IoIosArrowForward /></span>
                                </Link>
                                <ul className={`sidebar-dropdown ${activeDropdown === 6 ? 'active' : ''}`}>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/job-approval" className="sidebar-link">View Request</Link>
                                    </li>
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/add-jobs" className="sidebar-link">Job Form</Link>
                                    </li>
                                </ul>
                            </li>
                            {/* <li className={`sidebar-item ${activeDropdown === 7 ? 'active' : ''}`}>
                                <Link to="/dashboard/setting" className="sidebar-link"><IoSettingsSharp />
                                    Setting</Link>
                            </li> */}
                            <li className={`sidebar-item ${activeDropdown === 8 ? 'active' : ''}`}>
                                <Link to="/khiladiindia" className="sidebar-link dashboardlogout btn btn-danger text-white" onClick={handleLogout}><IoLogOut />
                                    Log Out</Link>
                            </li>
                        </ul>
                    </>
                    :
                    <>
                        <ul className="sidebar-nav">
                            <li className="sidebar-item">
                                <Link to="/dashboard" className="sidebar-link">
                                    <RiDashboard3Line /> Dashboard
                                </Link>
                            </li>
                            <li className={`sidebar-item ${activeDropdown === 1 ? 'active' : ''}`}>
                                <Link
                                    to="#"
                                    className="sidebar-link"
                                    onClick={() => handleDropdownToggle(1)}
                                >
                                    <FaUsers /> Vender Management <span> <IoIosArrowForward /></span>
                                </Link>
                                <ul className={`sidebar-dropdown ${activeDropdown === 1 ? 'active' : ''}`}>

                                    <li className="sidebar-item">
                                        <Link to="/dashboard/total-users" className="sidebar-link">Your Details</Link>
                                    </li>

                                </ul>
                            </li>
                            <li className={`sidebar-item ${activeDropdown === 2 ? 'active' : ''}`}>
                                <Link
                                    to="#"
                                    className="sidebar-link"
                                    onClick={() => handleDropdownToggle(2)}
                                >
                                    Job Post<span> <IoIosArrowForward /></span>
                                </Link>
                                <ul className={`sidebar-dropdown ${activeDropdown === 2 ? 'active' : ''}`}>
                                    {/* <li className="sidebar-item">
                                        <Link to="/dashboard" className="sidebar-link">Total Posted Jobs</Link>
                                    </li> */}
                                    <li className="sidebar-item">
                                        <Link to="/dashboard/add-jobs" className="sidebar-link">Job Form</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="sidebar-item">
                                <Link to="/khiladiindia" className="sidebar-link dashboardlogout btn btn-danger text-white" onClick={handleLogout}>Log Out</Link>
                            </li>
                        </ul>
                    </>
                }
            </div>
        </aside>
    );
}

export default Sidebar;
