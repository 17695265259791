import React, { useEffect, useState } from 'react';
import khiladiLogo from "../assets/logo.svg";
import DUMMYCITIES from './dummydata.json'
import { Form } from 'react-bootstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Slider from 'react-slick';

const HomeBanner = () => {
    const [vendors, setVendors] = useState([]);
    const [venders, setVenders] = useState([]);
    const [showTrainerModal, setShowTrainerModal] = useState(false);
    const [showJobModal, setShowJobModal] = useState(false);
    const [vendorCat, setVendorCat] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedDist, setSelectedDist] = useState("");
    const [selectedState, setSelectedState] = useState('Select State');
    const [districts, setDistricts] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await axios.get("/api/get_vender");
                setVenders(response.data);
            } catch (err) {
                console.error("Failed to fetch job posts. Please try again.");
            }
        };
        fetchJobs();
    }, []);

    // Extract unique categories and experience levels from the jobs data
    const uniqueCity = [...new Set(venders.map((vender) => vender.city))];
    const uniqueState = [...new Set(venders.map((vender) => vender.state))];
    const uniqueCategory = [...new Set(venders.map((vender) => vender.category))];

    useEffect(() => {
        axios
            .get("/api/home-banner")
            .then((response) => {
                setVendors(response.data);
            })
            .catch((error) => console.error("Error fetching vendors:", error));
    }, []);


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        beforeChange: (_, next) => setCurrentIndex(next),
    };


    const showTrainerModalHandler = () => {
        setShowTrainerModal(true);
    };


    const fetchCategories = () => {
        axios.get('/api/get-all-category')
            .then((response) => {
                setVendorCat(response.data);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });
    };


    const hideTrainerModal = () => {
        setShowTrainerModal(false);
    };

    const showJobModalHandler = () => {
        setShowJobModal(true);
    };

    const hideJobModal = () => {
        setShowJobModal(false);
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    // Handle state selection
    const handleStateChange = (e) => {
        const state = e.target.value;
        setSelectedState(state);
        setDistricts(DUMMYCITIES[state].sort() || []); // Set districts based on selected state
    };

    const handleDistrictChange = (e) => {
        setSelectedDist(e.target.value);
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % 4); // Cycle through 0-3
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    const filteredVendors = venders.filter((vender) => {
        const matchesState =
            selectedState === "" || vender.state === selectedState;
        const matchesDistrict =
            selectedDist === "" || vender.city.toLowerCase().includes(selectedDist.toLowerCase());
        const matchesCategory =
            selectedCategory === "" || vender.category?.category === selectedCategory;
        return matchesState && matchesDistrict && matchesCategory;
    });

    const handleSearch = () => {
        // Build the query string
        const queryParams = new URLSearchParams({
            state: selectedState,
            city: selectedDist,
            category: selectedCategory,
        }).toString();
        // Navigate to the VendorListingAcademy page with the query string
        navigate(`/vendor-listing/${selectedCategory}?${queryParams}`);
    };



    return (
        <div className='container-fluid px-5 homeBannerContainer'>
            <style>{styles.join('\n')}</style>

            <div className="row align-items-center flex-row-reverse">
                <div className="col-md-6 ">
                    <h2 className="mb-3 mt-5 text-center">Find Your Sport Solutions Near By</h2>
                    <p className="fs-20" style={{ lineHeight: "1.75rem" }}>
                        Seamlessly explore sports venues and play with sports enthusiasts just like you!
                    </p>
                    <div className="w-100 mx-auto mt-5">
                        <div className="searchSection row row-gap-3 fs-4">
                            {/* State Selector */}
                            <div className="col-12 searchSection1 col-md-6 col-lg-3">
                                <div>
                                    <Form.Select className='selectStaCitCat py-2' id="state" value={selectedState} onChange={handleStateChange}>
                                        <option value="">Select state</option>
                                        {Object.keys(DUMMYCITIES).map((state) => (
                                            <option key={state} value={state}>
                                                {state}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </div>

                            {/* District Selector */}
                            <div className="col-12 searchSection1 col-md-6 col-lg-3">
                                {selectedState && (
                                    <Form.Select className='selectStaCitCat py-2' id="district" onChange={handleDistrictChange}>
                                        <option value="">Select district</option>
                                        {districts.map((district) => (
                                            <option key={district} value={district}>
                                                {district}
                                            </option>
                                        ))}
                                    </Form.Select>
                                )}
                            </div>

                            {/* Category Selector */}
                            <div className="col-12 searchSection1 col-md-6 col-lg-3">
                                <Form.Select
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    className="form-select selectStaCitCat py-2 custom-select"
                                >
                                    <option value="" disabled>
                                        Select category
                                    </option>
                                    {vendorCat.map((cat) => (
                                        <option key={cat._id} value={cat.category}>
                                            {cat.category}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                            <div className="col-12 searchSection1 text-center col-md-6 col-lg-3 btn11 btn123">
                                <Link
                                    to="/vendor-listing-academy"
                                    className='Homepagesearch px-3' 
                                    state={{
                                        selectedState: selectedState,
                                        selectedCity: selectedDist,
                                        selectedCategory: selectedCategory,
                                    }}
                                >
                                    Search
                                </Link>
                            </div>
                        </div>
                        <div className="w-100 mt-5 mx-auto">
                            <div className="row d-flex align-items-center">
                                <div className="col-12 col-md-4">
                                    <div className="d-flex justify-content-center btn11">
                                        <Link to="/trainer-details"
                                            // onClick={handleRegisterClick}
                                            className="btn btn-success btn-jobs BecomeACerTra text-center cursor-pointer py-2 fs-18 rounded text-white fw-bold"
                                            style={{
                                                boxShadow: "rgba(0, 0, 0, 0.1) 4px 10px 12px",
                                                backgroundColor: "green",
                                                color: "white",
                                            }}
                                        >
                                            Become a Certified Trainer
                                        </Link>
                                    </div>
                                </div>

                                {/* Searching Trainers Button */}
                                <div className="col-12 col-md-4">
                                    <div className="d-flex justify-content-center btn11">
                                        <Link
                                            to='/trainer-listing'
                                            className="text-center btn-jobs BecomeACerTra cursor-pointer py-2 fs-18 rounded text-white fw-bold p-3"
                                            style={{
                                                boxShadow: "rgba(0, 0, 0, 0.1) 4px 10px 12px",
                                                backgroundColor: "#2991c8",
                                                color: "white",
                                            }}
                                        >
                                            Searching Trainers?
                                        </Link>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4">
                                    <div className="d-flex justify-content-center btn11">
                                        <Link to="/JobsListing"
                                            onClick={showJobModalHandler}
                                            className="btn  btn-jobs text-center cursor-pointer py-2 fs-18 rounded text-white fw-bold"
                                            style={{
                                                boxShadow: "rgba(0, 0, 0, 0.1) 4px 10px 12px",
                                                backgroundColor: "green",
                                                color: "white",
                                            }}
                                        >
                                            Looking for Jobs?
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mt-5 homeBannerMain">
                    <div
                        className="banner-img-container position-relative w-100 row d-flex"
                        style={{ borderRadius: "10px", overflow: "hidden", }}
                    >
                        {/* Left Image Slider (First User's Images) */}
                        <Link to={`/${vendors[0]?.vendorURL}`} className="w-50 leftHomeBanner p-0">
                        <Slider {...settings} >
                            {vendors?.[0]?.homeBanner1 && (
                                <div>
                                    <img
                                        className="w-100"
                                        src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[0].homeBanner1}`}
                                        alt="First user's slider"
                                        height="600px"
                                        style={{ objectFit: "cover" }}
                                    />
                                </div>
                            )}
                            {vendors?.[0]?.homeBanner2 && (
                                <div>
                                    <img
                                        className="w-100"
                                        src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[0].homeBanner2}`}
                                        alt="First user's slider"
                                        height="600px"
                                        style={{ objectFit: "cover" }}
                                    />
                                </div>
                            )}
                            {vendors?.[0]?.homeBanner3 && (
                                <div>
                                    <img
                                        className="w-100"
                                        src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[0].homeBanner3}`}
                                        alt="First user's slider"
                                        height="600px"
                                        style={{ objectFit: "cover" }}
                                    />
                                </div>
                            )}
                        </Slider>
                        </Link>
                        


                        {/* Right Top Image Slider (Second User's Images) */}
                        <div className="w-50 p-0">
                            <Link to={`/${vendors[1]?.vendorURL}`} className="w-100 rightTopHomeBanner p-0">
                            <Slider {...settings} >
                                {vendors?.[1]?.homeBanner1 && (
                                    <div>
                                        <img
                                            className="w-100 "
                                            src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[1].homeBanner1}`}
                                            alt="Second user's slider" height="300px"
                                            style={{ objectFit: "cover" }}
                                        />
                                    </div>
                                )}
                                {vendors?.[1]?.homeBanner2 && (
                                    <div>
                                        <img
                                            className="w-100"
                                            src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[1].homeBanner2}`}
                                            alt="Second user's slider" height="300px"
                                            style={{ objectFit: "cover" }}
                                        />
                                    </div>
                                )}
                                {vendors?.[1]?.homeBanner3 && (
                                    <div>
                                        <img
                                            className="w-100"
                                            src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[1].homeBanner3}`}
                                            alt="Second user's slider" height="300px"
                                            style={{ objectFit: "cover" }}
                                        />
                                    </div>
                                )}
                            </Slider>
                            </Link>
                            

                            {/* Right Bottom Image Slider (Third User's Images) */}
                            <Link to={`/${vendors[2]?.vendorURL}`}  className="w-100 rightBottomHomeBanner p-0">
                            <Slider {...settings}>
                                {vendors?.[2]?.homeBanner1 && (
                                    <div>
                                        <img
                                            className="w-100"
                                            src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[2].homeBanner1}`}
                                            alt="Third user's slider" height="300px"
                                            style={{ objectFit: "cover" }}
                                        />
                                    </div>
                                )}
                                {vendors?.[2]?.homeBanner2 && (
                                    <div>
                                        <img
                                            className="w-100 "
                                            src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[2].homeBanner2}`}
                                            alt="Third user's slider" height="300px"
                                            style={{ objectFit: "cover" }}
                                        />
                                    </div>
                                )}
                                {vendors?.[2]?.homeBanner3 && (
                                    <div>
                                        <img
                                            className="w-100"
                                            src={`https://api.khiladiindia.com/backend/uploads/homeBanner/${vendors[2].homeBanner3}`}
                                            alt="Third user's slider" height="300px"
                                            style={{ objectFit: "cover" }}
                                        />
                                    </div>
                                )}
                            </Slider>
                            </Link>
                            
                        </div>

                        {/* Center Logo */}
                        <div className="position-absolute hero-logo-container1">
                            <img
                                src={khiladiLogo}
                                alt="Hero Logo"
                                className="hero-logo1"
                                height="40px"
                            />
                        </div>
                    </div>
                </div>

            </div>

            {showTrainerModal && (
                <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <form action="">
                                    <div className="form-group mb-2">
                                        <label htmlFor="" className='form-label'>State</label>
                                        <Form.Select className='selectStaCitCat py-2' id="state" value={selectedState} onChange={handleStateChange}>
                                            <option value="">Select state</option>
                                            {Object.keys(DUMMYCITIES).map((state) => (
                                                <option key={state} value={state}>
                                                    {state}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                    <div className="form-group mb-2">
                                        <label htmlFor="" className='form-label'>City</label>
                                        {selectedState && (
                                            <Form.Select className='selectStaCitCat py-2' id="district" onChange={handleDistrictChange}>
                                                <option value="">Select district</option>
                                                {districts.map((district) => (
                                                    <option key={district} value={district}>
                                                        {district}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        )}
                                    </div>

                                    <div className="form-group mb-2">
                                        <label htmlFor="" className='form-label'>Coach/Trainer</label>
                                        <select type="text" className="form-control">
                                            <option value="">Select Coach</option>
                                            <option value="">Narendra</option>
                                            <option value="">Monty</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button className="noselect me-4 close-btn" onClick={hideTrainerModal}><span className="text">Close</span><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span></button>
                                <button type="button" className="rounded bannerpop">
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Job Modal */}
            {showJobModal && (
                <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body text-center">
                                <h5>Coming Soon</h5>
                            </div>
                            <div className="modal-footer">
                                <button className="noselect me-4 close-btn" onClick={hideJobModal}><span className="text">Close</span><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span></button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

// Define your CSS styles in an array
const styles = [
    `.btn123 .Homepagesearch{ padding:10px; border:1px solid #2991c8}`,

    // Existing styles...
    `.hero-logo-container { 
            top: 50%; 
            left: 50%; 
            transform: translate(-50%, -50%); 
        }`,
    `.hero-logo { 
            max-width: 100px; 
        }`,
    `@media (max-width: 768px) { 
            .hero-logo { 
                max-width: 200px; /* Reduce size for smaller screens */ 
            } 
                .homeBannerContainer{
                padding-left:20px !important;
                padding-right:20px !important;
                }
        }`,
    `@media (max-width: 576px) { 
            .hero-logo { 
                max-width: 200px; /* Further reduce size for extra small screens */ 
                padding-left:20px !important;
                padding-right:20px !important;
            } 
         .
        }`,


    `.btn11 Link:hover { background-color:rgb(9, 202, 54) !important; color: white; }`,
    `.btn-jobs { --color: #00A97F; --color2: rgb(10, 25, 30); background-color: transparent; border-radius: 6px; border: .3px solid var(--color); transition: .5s; position: relative; overflow: hidden; cursor: pointer; z-index: 1; font-weight: 300; font-size: 15px; font-family: 'Roboto', 'Segoe UI', sans-serif; text-transform: uppercase; color: var(--color);}`,
    `.btn-jobs::after, .btn-jobs::before { content: ''; display: block; height: 100%; width: 100%; transform: skew(90deg) translate(-50%, -50%); position: absolute; inset: 50%; left: 25%; z-index: -1; transition: .5s ease-out; background-color: var(--color);}`,
    `.btn-jobs::before { top: -50%; left: -25%; transform: skew(90deg) rotate(180deg) translate(-50%, -50%);}`,
    // `.btn-jobs:hover::before { transform: skew(45deg) rotate(180deg) translate(-50%, -50%);}`,
    // `.btn-jobs:hover::after { transform: skew(45deg) translate(-50%, -50%);}`,
    `.btn-jobs:hover { color: var(--color2);}`,
    `.btn-jobs:active { filter: brightness(.7); transform: scale(.98);}`,
    `.bannerpop { background-color: #2991c8 !important; color: white; }`,
    `.bannerpop:hover { background-color: #2179a4 !important; color: white; }`,
    `input[type="text"]:hover { border-color: blue; }`,
    `.custom-select { max-height: 200px !important; overflow-y: auto !important; }`,  // Custom height for the dropdown
    `@media (max-width: 768px) { .btn11 { flex-direction: column; } .btn11 .Homepagesearch { width: 100%; margin-bottom: 10px; } .btn11 .BecomeACerTra{ width: 100% !important; margin-bottom: 10px; }}`,
    `@media (max-width: 1447px){.btn-jobs { --color: #00A97F; --color2: rgb(10, 25, 30); background-color: transparent; border-radius: 6px; border: .3px solid var(--color); transition: .5s; position: relative; overflow: hidden; cursor: pointer; z-index: 1; font-weight: 300; font-size: 12px; font-family: 'Roboto', 'Segoe UI', sans-serif; text-transform: uppercase; color: var(--color);}`,
    `@media (max-width: 1447px){.searchSection1 {  font-weight: 300; font-size: 12px; font-family: 'Roboto', 'Segoe UI', sans-serif; text-transform: uppercase; color: var(--color);}`,
    `.close-btn { width: 150px; height: 50px; cursor: pointer; display: flex; align-items: center; background: #e62222; border: none; border-radius: 5px; box-shadow: 1px 1px 3px rgba(0,0,0,0.15);}`,
    `.close-btn, .close-btn span { transition: 200ms;}`,
    `.close-btn .text { transform: translateX(35px); color: white; font-weight: bold;}`,
    `.close-btn .icon { position: absolute; border-left: 1px solid #c41b1b; transform: translateX(110px); height: 40px; width: 40px; display: flex; align-items: center; justify-content: center;}`,
    `.close-btn:hover { background: #ff3636;}`,
    `.close-btn:hover .text { color: transparent;}`,
    `.close-btn:hover .icon { width: 150px; border-left: none; transform: translateX(0);}`,
    `.close-btn:focus { outline: none;}`,
    `.close-btn:active .icon svg { transform: scale(0.8);}`,
    `.custom-select { max-height: 200px !important; overflow-y: auto !important; }`,
    `option{height:100px !important; overflow-y:scroll !important;}`,
];

export default HomeBanner;
