
import React, { useRef } from "react";
import Footer from "../components/Footer";

const Home = () => {
  const formRef = useRef(null);

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div>
      <h1 className="text-center text-2xl my-4">Welcome to My Page</h1>

      {/* Form Component */}
      <div ref={formRef} className="max-w-md mx-auto p-6 bg-gray-100 shadow-md rounded mt-10">
        <h2 className="text-xl font-bold mb-4">Contact Form</h2>
        <input type="text" placeholder="Your Name" className="w-full p-2 mb-4 border border-gray-300 rounded" />
        <input type="email" placeholder="Your Email" className="w-full p-2 mb-4 border border-gray-300 rounded" />
        <textarea placeholder="Your Message" className="w-full p-2 mb-4 border border-gray-300 rounded"></textarea>
        <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Submit</button>
      </div>

      {/* Footer */}
      <Footer scrollToForm={scrollToForm} />
    </div>
  );
};

export default Home;