import React, { useEffect, useState } from 'react';
import myphoto7 from '../assets/khiladilogo.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import comingSoonGif from '../assets/coming-soon.gif';

function AcademySports() {
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    axios.get("/api/sportsAcademy")
      .then((response) => setVendors(response.data))
      .catch((error) => console.error("Error fetching vendors:", error));
  }, []);

  // Filter out vendors missing name or vendorImage1
  const filteredVendors = vendors.filter(vendor => vendor.name && vendor.vendorImage1);

  return (
    <>
      <div className="container mt-5">
        {/* Title and Link Row */}
        <div className="row justify-content-center mt-5 mb-4">
          <div className="col-12 text-center">
            <h2>Sports Academy</h2>
          </div>
        </div>

        {/* Service Cards */}
        <div className="row row-gap-4">
          {filteredVendors.length > 0 ? (
            filteredVendors.map((item) => (
              <div className="custom-cardAcademy" key={item.id}>
                <div className="card px-0 pt-0 h-100">
                  <img
                    src={`https://api.khiladiindia.com/backend/uploads/ShopImage/${item.vendorImage1}`}
                    className="card-img-top"
                    alt={item.name}
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">{item.name}</h5>
                    <p className="card-text">{item.about}</p>
                    <Link to={`/Sports-Academy/${item.vendorURL}`} className="btn btn-primary rounded checkservice">
                      Check Service
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center elseincard">
            <img src={comingSoonGif} alt="Coming Soon"/>
            <h5>Academy Sports - Coming Soon!</h5>
          </div>
          )}
        </div>

        {/* More Services Link */}
        <div className="mt-4">
          <Link to="/Sports-Academy" className="more-services-link">
            More Services
          </Link>
        </div>
      </div>
    </>
  );
}

export default AcademySports;
