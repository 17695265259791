import React, { useEffect, useState } from 'react';
import "./StayHealthy.css";
import "./owl.carousel.min.css";
import "./owl.theme.default.min.css";
import "./aos.css";
import { Link } from 'react-router-dom';
import axios from 'axios';
import comingSoonGif from '../assets/coming-soon.gif';

export default function DeffenceTrainingCe() {
  const [vendors, setVendors] = useState([]);

  const fetchVendors = async () => {
    try {
      const response = await axios.get("/api/defencevenders");
      setVendors(response.data || []);
    } catch (error) {
      console.error("Error fetching vendors:", error.message);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  // Filter out vendors missing vendorName or profileImage
  const filteredVendors = vendors.filter(vendor => vendor.vendorName && vendor.profileImage);

  return (
    <div className="site-section" id="classes-section">
      <div className="site-section1" id="classes-section1">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-8 section-heading">
              <h2 className="heading">Defence Training Center</h2>
            </div>
          </div>
          
          {filteredVendors.length > 0 ? (
            <div className="row u--zoomIn">
              {filteredVendors.map(vendor => (
                <Link to={`/Deffence-Center/${vendor.vendorURL}`} className="col-lg-6" key={vendor._id}>
                  <div className="class-item d-flex align-items-center">
                    <div className="class-item-thumbnail">
                      <img src={`https://api.khiladiindia.com/backend/uploads/homepage/${vendor.profileImage}`} alt={vendor.vendorName} />
                    </div>
                    <div className="class-item-text">
                      <h2 className='text-white'>{vendor.vendorName}</h2>
                      <span>{vendor.about}</span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <div className="text-center elseincard">
            <img src={comingSoonGif} alt="Coming Soon"/>
            <h5>Defence Trainers - Coming Soon!</h5>
          </div>
          )}
          <Link to="/Deffence-Center" className="more-trainers-link">More Trainers</Link>
        </div>
      </div>
    </div>
  );
}
