import React, { useEffect, useState } from 'react';
import './PopUp.css';
import whatsapp from '../assets/Whatsapp.gif';
import registeralert from '../assets/registerAlert.png';
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai';


function App() {
  const [showForm, setShowForm] = useState(false);
  const [vendorCat, setVendorCat] = useState([]);
  const [selectedOption, setSelectedOption] = useState('Free'); // Default to "Free"
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    city: '',
    gender: '',
    category: '',
    paymentType: '',
  });


  const fetchCategories = () => {
    axios.get('/api/get-all-category')
      .then((response) => {
        setVendorCat(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappMessage = `Full Name: ${formData.name}\nBusiness Name: ${formData.businessName}\nAddress: ${formData.Address}\nMap Location: ${formData.mapLocation}\nEmail: ${formData.email}\nNumber: ${formData.number}\nCity: ${formData.city}\nGender: ${formData.gender}\nCategory: ${formData.category}\nPayment Option: ${selectedOption}`;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=8279848804&text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, '_blank');
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const toggleForm = () => {
    setShowForm(!showForm);
    if (!showForm) {
      setTimeout(() => {
        document.querySelector('.contact-form-wrapper')?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 0);
    }
  };


  return (
    <div className="App">
      <div className="registerMe">
        <img src={registeralert} alt='Register Now' className='form titles registerImg' onClick={toggleForm} style={{ color: "#28a745", width: "100px", fontSize: "70px" }}></img>
      </div>
      <img src={whatsapp} alt='Whatsapp icon' className='form titles openFormBtn' onClick={toggleForm} style={{ color: "#28a745", fontSize: "70px" }}></img>
      {showForm && (
        <div className="contact-form-wrapper">
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="form-title">How Can We Help?</h3>
              <button className="popupclose" onClick={toggleForm}>
                <AiOutlineClose size={20} />
              </button>
            </div>
            <hr className='mb-2 mt-2 ' />
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  id="name"
                  placeholder="Full Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  placeholder="Enter City"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="businessName">Business Name</label>
                <input
                  type="businessName"
                  id="businessName"
                  placeholder="Business Name"
                  value={formData.businessName}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="Address">Address</label>
                <input
                  type="text"
                  id="Address"
                  placeholder="Address"
                  value={formData.Address}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="number">Mobile</label>
                <input
                  type="tel"
                  id="number"
                  placeholder="Enter Mo. Number"
                  value={formData.number}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="gender">Gender</label>
                <select
                  id="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="category">Category</label>
                <select
                  id="category"
                  value={formData.category}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select a category
                  </option>
                  {vendorCat.map((cat) => (
                    <option key={cat._id} value={cat.category}>
                      {cat.category}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>Enter Your Map Location</label>
                <input
                  type="text"
                  id="mapLocation"
                  placeholder="Location URL"
                  value={formData.mapLocation}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="paymentType">Payment Option</label>
                <select
                  id="paymentType"
                  value={formData.paymentType}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Payment Type</option>
                  <option value="free">Free</option>
                  <option value="paid">Paid</option>
                </select>
              </div>
            </div>
            <hr></hr>
            <div className="row">
              <div className="form-footer col-md-8">
                <button type="submit" className="submit-btn">Send Message</button>
              </div>
              <div className="form-footer col-md-4">
                <button type="button" className="close1-btn" onClick={toggleForm}>
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default App;
