import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./SliderBanner.css";
import comingSoonGif from '../assets/coming-soon.gif';

const ImageCardSlider = () => {
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    axios.get("/api/shopvendor")
      .then((response) => setVendors(response.data))
      .catch((error) => console.error("Error fetching vendors:", error));
  }, []);

  // Custom Left Arrow Component
  const PrevArrow = ({ onClick }) => (
    <div className="slider-arrow prev" onClick={onClick}>
      <FaChevronLeft />
    </div>
  );

  // Custom Right Arrow Component
  const NextArrow = ({ onClick }) => (
    <div className="slider-arrow next" onClick={onClick}>
      <FaChevronRight />
    </div>
  );

  // Slider settings with arrows
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024, // Tablet screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Mobile screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Filter out vendors missing name or vendorImage
  const filteredVendors = vendors.filter(vendor => vendor.name && vendor.vendorImage);

  return (
    <div className="container">
      <div className="slider-container">
        <div className="slider-title px-3 mb-3">
          <h2>Sports Shop</h2>
        </div>
        
        {filteredVendors.length > 0 ? (
          <Slider className="myslider" {...settings}>
            {filteredVendors.map((item) => (
              <div className="card" key={item.id}>
                <img
                  src={`https://api.khiladiindia.com/backend/uploads/ShopImage/${item.vendorImage}`}
                  alt={item.name}
                  className="card-image"
                />
                <div className="card-content">
                  <h3 className="card-title">{item.name}</h3>
                  <p className="card-description">{item.about}</p>
                  <a href={`/Sports-Shop/${item.vendorURL}`} className="btntrainer">
                    Know More
                  </a>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <div className="text-center elseincard">
            <img src={comingSoonGif} alt="Coming Soon"/>
            <h5>Sports Shop - Coming Soon!</h5>
          </div>
        )}

        <Link to="/Sports-Shop" className="more-trainers-link">
          More Trainers
        </Link>
      </div>
    </div>
  );
};

export default ImageCardSlider;
