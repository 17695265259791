import React from 'react'
import HomeBanner from '../components/HomeBanner'
import CategoryMenu from '../components/CategoryMenu'
import WeatherApp from '../components/WeatherApp'
import Trainers from "../components/SportsShop"
import RatedTrainers from '../components/RatedTrainers'
import ContactForm from '../components/ContactForm'
import Service2 from '../components/CategoryShort'
import WhyJoinUs from '../components/WhyJoinUs'
import DeffenceTrainingCe from '../components/DeffenceTrainingCe'
import SportsEquipmentCat from '../components/SportsEquipmentCat';
import TrainerRegistration from '../components/TrainerForm';
import TestimonialSection from '../components/Testimonial';
import AcademySports from '../components/AcademySports';
import KabaddiTrainers from '../components/KabaddiTrainers'
import FitnessTrainer from '../components/FitnessTrainer'


function HomePage() {
  return (
    <div>
      <HomeBanner />  
      <CategoryMenu />
      <SportsEquipmentCat/>
      <DeffenceTrainingCe/>
      <Trainers />
      <FitnessTrainer/>
      <WhyJoinUs/>
      <Service2 />
      <AcademySports />
      <KabaddiTrainers/>
      <RatedTrainers />
      <TrainerRegistration/>
      <WeatherApp />
      <ContactForm />
      <TestimonialSection/>
    </div>
  )
}

export default HomePage
