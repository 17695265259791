import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import DUMMYCITIES from "../components/dummydata.json";
import Swal from 'sweetalert2';
import axios from 'axios';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const RegisterAccount = () => {
    const [vendorCat, setVendorCat] = useState([]);
    const [jobTitle, setJobTitle] = useState("");
    const [numOpenings, setNumOpenings] = useState(1);
    const [jobType, setJobType] = useState("Full Time");
    const [workLocation, setWorkLocation] = useState("");
    const [city, setCity] = useState("");
    const [locality, setLocality] = useState("");
    const [gender, setGender] = useState("Male");
    const [qualification, setQualification] = useState("Any");
    const [minExp, setMinExp] = useState("");
    const [maxExp, setMaxExp] = useState("");
    const [interviewAddress, setInterviewAddress] = useState("");
    const [allowCalls, setAllowCalls] = useState(true);
    const [minSalary, setMinSalary] = useState("");
    const [maxSalary, setMaxSalary] = useState("");
    const [benefits, setBenefits] = useState([]);
    const [skills, setSkills] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [jobTiming, setJobTiming] = useState({ start: "09:00", end: "18:00" });
    const [workingDays, setWorkingDays] = useState("");
    const [newSkill, setNewSkill] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [activeForm, setActiveForm] = useState('vendor'); // 'vendor' or 'job'
    const navigate = useNavigate();
    const [vendorFormData, setVendorFormData] = useState({
        phone: "",
        category: "",
        title: "",
        password: "",
        confirmPassword: "",
        email: "",
    });

    const handleAddSkill = () => {
        if (newSkill && !skills.includes(newSkill)) {
            setSkills([newSkill, ...skills]);
            setNewSkill("");
        }
    };

    const toggleSelection2 = (skill) => {
        if (skills.includes(skill)) {
            setSkills(skills.filter((s) => s !== skill)); // Remove if already selected
        } else {
            setSkills([skill, ...skills]); // Add skill to the top if not selected
        }
    };

    const benefitsOptions = ["Cab", "Meal", "Insurance", "PF", "Medical Benefits"];
    const documentsOptions = ["PAN Card", "Aadhar Card", "Bank Account", "Academic Certificate"];
    const workingDaysOptions = ["5 days working", "6 days working", "Other"];
    const toggleSelection = (option, list, setList) => {
        if (list.includes(option)) {
            setList(list.filter((item) => item !== option));
        } else {
            setList([...list, option]);
        }
    };

    useEffect(() => {
        axios.get('/api/get-all-category')
            .then((response) => {
                setVendorCat(response.data);
            })
            .catch((error) => {
                console.error('Error fetching categories:', error);
            });
    }, []);

    const handleVendorInputChange = (e) => {
        const { name, value } = e.target;
        setVendorFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleJobPostSubmit = async (e) => {
        e.preventDefault();

         // Initialize navigation function

        const user = JSON.parse(localStorage.getItem("vendorData"));

        if (!user || !user.data?.token) {
            Swal.fire("Error!", "You are not authenticated. Please log in again.", "error");
            return;
        }

        let token = user.data.token;

        const jobData = {
            jobTitle,
            numOpenings,
            jobType,
            workLocation,
            city,
            locality,
            gender,
            qualification,
            minExp,
            maxExp,
            interviewAddress,
            allowCalls,
            minSalary,
            maxSalary,
            benefits,
            skills,
            documents,
            jobTiming,
            workingDays,
        };

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        try {
            const response = await axios.post("/api/create", jobData, config);

            if (response.status === 201) {
                Swal.fire({
                    title: "Success!",
                    text: "Job post created successfully.",
                    icon: "success",
                    confirmButtonText: "OK"
                }).then(() => {
                    localStorage.removeItem("vendorData");
                    setActiveForm("vendor");
                    navigate("/"); 
                });
            } else {
                Swal.fire("Error!", response.data.message || "Failed to create job post.", "error");
            }
        } catch (error) {
            console.error("Error submitting job post:", error.response?.data || error.message);
            Swal.fire("Error!", error.response?.data?.message || "Failed to create job post.", "error");
        }
    };

    const handleVendorSubmit = async (e) => {
        e.preventDefault();

        if (vendorFormData.password !== vendorFormData.confirmPassword) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Passwords do not match.',
            });
            return;
        }

        try {
            const response = await axios.post("/api/add_vender", vendorFormData, {
                headers: { "Content-Type": "application/json" },
            });
            Swal.fire("Success!", "Step 1 done. Please activate your job by clicking the link sent to your email.", "success");

            localStorage.setItem('vendorData', JSON.stringify(response.data))
            // Show the second form after vendor form submission
            setActiveForm('job');
        } catch (error) {
            console.error("Error:", error.response?.data || error.message);
            Swal.fire("Error!", "Failed to upload vendor.", "error");
        }
    };

    const sortedCities = Object.values(DUMMYCITIES)
        .flat()
        .map((city) => city.trim())
        .sort((a, b) => a.localeCompare(b));
    return (

        <div className="container my-5">
            <div className=" createjobheader d-flex">

                <div className="">
                    <h2 className=" my-0 login-title">Welcome</h2>
                    <p>Register for a   seamless experience</p>
                </div>
            </div>
            {activeForm === 'vendor' && (
                <div className="">
                    <form onSubmit={handleVendorSubmit} className="row">
                        <h3>Job post Step-1</h3>
                        <div className="mb-3 col-md-6 col-12">
                            <label className="form-label">Business Name *</label>
                            <input
                                type="text"
                                className="form-control"
                                name="title"
                                value={vendorFormData.title}
                                onChange={handleVendorInputChange}
                                required
                            />
                        </div>
                        
                        <div className="mb-3 col-md-6 col-12">
                            <label className="form-label"> Mobile Number *</label>
                            <input
                                type="text"
                                className="form-control"
                                name="phone"
                                placeholder="Enter Your Number"
                                value={vendorFormData.phone}
                                onChange={handleVendorInputChange}
                                maxLength="10" // Restricts input length to 10
                                pattern="\d{10}" // Ensures only 10 digits are entered
                                required
                            />
                        </div>
                        <div className="mb-3 col-md-6 col-12">
                            <label className="form-label">Email Id *</label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Your email"
                                value={vendorFormData.email}
                                onChange={handleVendorInputChange}
                                min="1"
                                name="email"
                                required
                            />
                        </div>
                        <div className="mb-3 col-md-6 col-12">
                            <label className="form-label">Category *</label>
                            <select
                                className="form-control"
                                name="category"
                                value={vendorFormData.category}
                                onChange={handleVendorInputChange}
                                required
                            >
                                <option value="">Select Category *</option>
                                {vendorCat.map((cat) => (
                                    <option key={cat._id} value={cat._id}>{cat.category}</option>
                                ))}
                            </select>
                        </div>


                        <div className="mb-3 col-md-6 col-12">
                            <label className="form-label">Password *</label>
                            <div className="position-relative">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    name="password"
                                    value={vendorFormData.password}
                                    onChange={handleVendorInputChange}
                                    required
                                />
                                <span
                                    className="position-absolute top-50 end-0 translate-middle-y me-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </span>
                            </div>
                        </div>

                        <div className="mb-3 col-md-6 col-12">
                            <label className="form-label">Confirm Password *</label>
                            <div className="position-relative">
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    className="form-control"
                                    name="confirmPassword"
                                    value={vendorFormData.confirmPassword}
                                    onChange={handleVendorInputChange}
                                    required
                                />
                                <span
                                    className="position-absolute top-50 end-0 translate-middle-y me-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                >
                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                </span>
                            </div>
                        </div>


                        <button type="submit" className=" postjobbutton m-auto me-0 " ><span>Step 2</span></button>
                    </form>
                </div>

            )}
            <hr />

            {activeForm === 'job' && (

                <form onSubmit={handleJobPostSubmit} className="jobFormMain row">
                    <div className="mb-3 col-md-6 col-12">
                        <label className="form-label">Job Title *</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Title of job"
                            value={jobTitle}
                            onChange={(e) => setJobTitle(e.target.value)}
                            required
                        />
                    </div>


                    {/* Number of Openings */}
                    <div className="mb-3 col-md-6 col-12">
                        <label className="form-label">Number of Openings *</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="e.g., 1"
                            value={numOpenings}
                            onChange={(e) => setNumOpenings(e.target.value)}
                            min="1"
                            required
                        />
                    </div>

                    {/* Job Type */}
                    <div className="mb-3">
                        <label className="form-label">Job Type *</label>
                        <div>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${jobType === "Full Time" ? "selected" : "unselected"}`}
                                onClick={() => setJobType("Full Time")}
                            >
                                Full Time
                            </button>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${jobType === "Part Time" ? "selected" : "unselected"}`}
                                onClick={() => setJobType("Part Time")}
                            >
                                Part Time
                            </button>
                        </div>
                    </div>

                    {/* Work Location Type */}
                    <div className="mb-3">
                        <label className="form-label">Work Location Type *</label>
                        <div>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${workLocation === "Work from home" ? "selected" : "unselected"}`}
                                onClick={() => setWorkLocation("Work from home")}
                            >
                                Work from home
                            </button>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${workLocation === "Work from office" ? "selected" : "unselected"}`}
                                onClick={() => setWorkLocation("Work from office")}
                            >
                                Work from office
                            </button>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${workLocation === "Field job" ? "selected" : "unselected"}`}
                                onClick={() => setWorkLocation("Field job")}
                            >
                                Field job
                            </button>
                        </div>
                    </div>

                    {/* Choose City */}
                    <div className="mb-3 row">
                        <div className="col-md-3">
                            <label className="form-label">Choose City *</label>
                            <select
                                className="form-control"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                            >
                                <option value="">Select a city</option> {/* Default placeholder */}
                                {sortedCities.map((cityOption, i) => (
                                    <option key={i} value={cityOption}>
                                        {cityOption}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-3">
                            <label className="form-label">Job Locality *</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Type Your Local Address"
                                value={locality}
                                onChange={(e) => setLocality(e.target.value)}
                                required
                            />
                        </div>
                    </div>
                    {/* Gender */}
                    <div className="mb-3">
                        <label className="form-label">Gender *</label>
                        <div>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${gender === "Male" ? "selected" : "unselected"}`}
                                onClick={() => setGender("Male")}
                            >
                                Male
                            </button>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${gender === "Female" ? "selected" : "unselected"}`}
                                onClick={() => setGender("Female")}
                            >
                                Female
                            </button>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${gender === "Other" ? "selected" : "unselected"}`}
                                onClick={() => setGender("Other")}
                            >
                                Other
                            </button>
                        </div>
                    </div>
                    {/* Minimum Qualification Required */}

                    <div className="mb-3">
                        <label className="form-label">Minimum Qualification Required *</label>
                        <div>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${qualification === "Any" ? "selected" : "unselected"}`}
                                onClick={() => setQualification("Any")}
                            >
                                Any
                            </button>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${qualification === "10th" ? "selected" : "unselected"}`}
                                onClick={() => setQualification("10th")}
                            >
                                10th
                            </button>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${qualification === "12th" ? "selected" : "unselected"}`}
                                onClick={() => setQualification("12th")}
                            >
                                12th
                            </button>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${qualification === "Diploma" ? "selected" : "unselected"}`}
                                onClick={() => setQualification("Diploma")}
                            >
                                Diploma
                            </button>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${qualification === "Graduate" ? "selected" : "unselected"}`}
                                onClick={() => setQualification("Graduate")}
                            >
                                Graduate
                            </button>
                            <button
                                type="button"
                                className={`btn me-2 selectjobpost ${qualification === "Post Graduate" ? "selected" : "unselected"}`}
                                onClick={() => setQualification("Post Graduate")}
                            >
                                Post Graduate
                            </button>
                        </div>
                    </div>

                    {/* Required Experience */}
                    <div className="mb-3">
                        <label className="form-label">Required Experience *</label>
                        <div className="row">
                            {/* Min Experience Dropdown */}
                            <div className="col-md-3">
                                <select
                                    className="form-select minExpJob"
                                    value={minExp}
                                    onChange={(e) => setMinExp(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>
                                        Min exp.
                                    </option>
                                    <option value="0 month">0 month</option>
                                    <option value="6 months">6 months</option>
                                    <option value="1 year">1 year</option>
                                    <option value="2 years">2 years</option>
                                    <option value="3 years">3 years</option>
                                    <option value="4 years">4 years</option>
                                    <option value="5 years">5 years</option>
                                    <option value="6+ years">6+ years</option>
                                </select>
                            </div>
                            <div className="col-md-1"><p className="text-center">to</p></div>
                            {/* Max Experience Dropdown */}
                            <div className="col-md-3">
                                <select
                                    className="form-select maxExpJob"
                                    value={maxExp}
                                    onChange={(e) => setMaxExp(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>
                                        Max exp.
                                    </option>
                                    <option value="1 year">1 year</option>
                                    <option value="2 years">2 years</option>
                                    <option value="3 years">3 years</option>
                                    <option value="4 years">4 years</option>
                                    <option value="5 years">5 years</option>
                                    <option value="6 years">6 years</option>
                                    <option value="6+ years">6+ years</option>
                                </select>
                            </div>
                        </div>

                    </div>

                    <div className="mb-3">
                        <label className="form-label">Salary Details (Monthly) *</label>
                        <div className="row">
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Min Salary"
                                    value={minSalary}
                                    onChange={(e) => setMinSalary(e.target.value)}
                                />
                            </div>
                            <div className="col-md-1"><p className="text-center">to</p></div>
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Max Salary"
                                    value={maxSalary}
                                    onChange={(e) => setMaxSalary(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Job Benefits (Optional)</label>
                        <div>
                            {benefitsOptions.map((benefit) => (
                                <button
                                    key={benefit}
                                    type="button"
                                    className={`btn me-2 selectjobpost ${benefits.includes(benefit) ? "selected" : "unselected"}`}
                                    onClick={() => toggleSelection(benefit, benefits, setBenefits)}
                                >
                                    {benefit}
                                </button>
                            ))}
                        </div>
                    </div>


                    <div className="mb-3 col-md-6 col-12">
                        <label className="form-label">Job Skills (Optional)</label>
                        <div>
                            {/* Display existing skills as buttons */}
                            {skills.map((skill) => (
                                <button
                                    key={skill}
                                    type="button"
                                    className={`btn me-2 ${skills.includes(skill) ? "selected" : "unselected"}`}
                                    onClick={() => toggleSelection2(skill)}
                                >
                                    {skill}
                                </button>
                            ))}

                            {/* Input field for adding a new skill */}
                            <input
                                type="text"
                                className="form-control mt-2"
                                value={newSkill}
                                onChange={(e) => setNewSkill(e.target.value)}
                                placeholder="Add a new skill"
                            />

                            {/* Button to add the new skill */}
                            <button
                                type="button"
                                className="btn btn-primary mt-2 selectskilss"
                                onClick={handleAddSkill}
                            >
                                Add Skill
                            </button>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Documents Required for this Job (Optional)</label>
                        <div>
                            {documentsOptions.map((document) => (
                                <button
                                    key={document}
                                    type="button"
                                    className={`btn me-2 selectjobpost ${documents.includes(document) ? "selected" : "unselected"}`}
                                    onClick={() => toggleSelection(document, documents, setDocuments)}
                                >
                                    {document}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Job Timings *</label>
                        <div className="row">
                            <div className="col-md-3">
                                <input
                                    type="time"
                                    className="form-control"
                                    value={jobTiming.start}
                                    onChange={(e) => setJobTiming({ ...jobTiming, start: e.target.value })}
                                />
                            </div>
                            <div className="col-md-1"><p className="text-center">to</p></div>
                            <div className="col-md-3">
                                <input
                                    type="time"
                                    className="form-control"
                                    value={jobTiming.end}
                                    onChange={(e) => setJobTiming({ ...jobTiming, end: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Working Days *</label>
                        <div>
                            {workingDaysOptions.map((day) => (
                                <button
                                    key={day}
                                    type="button"
                                    className={`btn me-2 selectjobpost ${workingDays === day ? "selected" : "unselected"}`}
                                    onClick={() => setWorkingDays(day)}
                                >
                                    {day}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Full Interview Address */}
                    <div className="mb-3 col-md-6 col-12">
                        <label className="form-label">Full Interview Address *</label>
                        <textarea
                            // type="text"
                            className="form-control"
                            cols="3"
                            rows="3"
                            value={interviewAddress}
                            onChange={(e) => setInterviewAddress(e.target.value)}
                            required
                        />
                        {/* <small className="form-text text-muted">
                    <a href="#edit" className="text-decoration-none">Edit</a>
                  </small> */}
                    </div>

                    {/* Communication Preferences */}
                    <div className="mb-3">
                        <label className="form-label">Communication Preferences</label>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={allowCalls}
                                onChange={(e) => setAllowCalls(e.target.checked)}
                            />
                            <label className="form-check-label">
                                Allow candidates to call between 10:00 am - 07:00 pm
                            </label>
                        </div>

                    </div>
                    <hr></hr>

                    {/* Submit Button */}
                    <div className="jobpostbuttonupper">
                        <button type="submit jobpostsubmitbutton" className="btn btn-primary text-white">
                            Post this job
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default RegisterAccount;
