import React, { useEffect, useState } from 'react';
import "./StayHealthy.css";
import "./owl.carousel.min.css";
import "./owl.theme.default.min.css";
import "./aos.css";
import { Link } from 'react-router-dom';
import axios from 'axios';
import comingSoonGif from '../assets/coming-soon.gif';

export default function KabaddiTrainers() {
  const [vendors, setVendors] = useState([]);

  const fetchVendors = async () => {
    try {
      const response = await axios.get("/api/kabaddiTrainer");
      setVendors(response.data || []);
    } catch (error) {
      console.error("Error fetching vendors:", error.message);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  // Filter out vendors missing vendorName or profileImage2
  const filteredVendors = vendors.filter(vendor => vendor.vendorName && vendor.profileImage2);

  return (
    <div className="site-section" id="classes-section">
      <div className="container">
        <div className="row justify-content-center text-center mb-5">
          <div className="col-md-8 section-heading">
            <h2 className="heading mb-3">Kabaddi Training Center</h2>
          </div>
        </div>

        {filteredVendors.length > 0 ? (
          <div className="row u--zoomIn">
            {filteredVendors.map((exercise) => (
              <div className="col-lg-6" key={exercise.id}>
                {/* Wrap the entire card in the Link */}
                <Link to={`/Kabaddi-Training/${exercise.vendorURL}`} className="class-item d-flex align-items-center ">
                  <div className="class-item-thumbnail">
                    <img src={`https://api.khiladiindia.com/backend/uploads/homepage/${exercise.profileImage2}`} alt={exercise.vendorName} />
                  </div>
                  <div className="class-item-text">
                    <h2 className='text-white'>{exercise.vendorName}</h2>
                    <span>{exercise.about}</span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center elseincard">
            <img src={comingSoonGif} alt="Coming Soon"/>
            <h5>Kabaddi Trainers - Coming Soon!</h5>
          </div>
        )}

        <div className="center-button">
          <Link to="/Kabaddi-Training" className="more-trainers-link">More Services</Link>
        </div>
      </div>
    </div>
  );
}
