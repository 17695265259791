import React, { useEffect, useState } from 'react';
import "./StayHealthy.css";
import "./owl.carousel.min.css";
import "./owl.theme.default.min.css";
import "./aos.css";
import { Link } from 'react-router-dom';
import axios from 'axios';
import comingSoonGif from '../assets/coming-soon.gif';

export default function FitnessTrainer() {
  const [vendors, setVendors] = useState([]);

  const fetchVendors = async () => {
    try {
      const response = await axios.get("/api/fitnessTrainer");
      setVendors(response.data || []);
    } catch (error) {
      console.error("Error fetching vendors:", error.message);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  // Filter out vendors missing vendorName or profileImage1
  const filteredVendors = vendors.filter(vendor => vendor.vendorName && vendor.profileImage1);

  return (
    <div className="site-section" id="classes-section">
      <div className="container">
        <div className="row justify-content-center text-center mb-5">
          <div className="col-md-8 section-heading">
            <h2 className="heading mb-3">Fitness Trainer & Sports Coach</h2>
            <p>
              Our gym offers a variety of classes targeting different muscle groups, designed by expert trainers.
              Whether you're looking to build strength, tone your body, or improve your endurance, we have the
              perfect workout routine for you.
            </p>
          </div>
        </div>

        {filteredVendors.length > 0 ? (
          <div className="row u--zoomIn">
            {filteredVendors.map((exercise) => (
              <div className="col-lg-6" key={exercise.id}>
                {/* Wrap the entire card in the Link */}
                <Link to={`/Fitness-Trainer/${exercise.vendorURL}`} className="class-item d-flex align-items-center">
                  <div className="class-item-thumbnail">
                    <img src={`https://api.khiladiindia.com/backend/uploads/homepage/${exercise.profileImage1}`} alt={exercise.vendorName} />
                  </div>
                  <div className="class-item-text">
                    <h2 className='text-white'>{exercise.vendorName}</h2>
                    <span>{exercise.about}</span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center elseincard">
            <img src={comingSoonGif} alt="Coming Soon"/>
            <h5>Fitness Trainers - Coming Soon!</h5>
          </div>
        )}

        <div className="center-button">
          <Link to="/Fitness-Trainer" className="more-trainers-link">More Services</Link>
        </div>
      </div>
    </div>
  );
}
