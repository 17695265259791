import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { IoIosAddCircleOutline } from "react-icons/io";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';
// import SingleVendorUser from "./SingleVendorUser";

const TotalTrainers= () => {
  const [users, setCategoryAndUser] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      const table = $('#example').DataTable();
      return () => table.destroy(); // Cleanup DataTable on unmount or re-render
    }
  }, [users]);

  // Fetch users and sort them by createdAt in descending order
  const fetchUser = () => {
    axios
      .get("/api/trainer/")
      .then((response) => {
        // Sort the fetched users by createdAt in descending order (newest first)
        const sortedUsers = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setCategoryAndUser(sortedUsers);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  const deleteHandleUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/api/trainer/${id}`)
          .then(() => {
            window.location.reload();
            Swal.fire("Deleted!", "User has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting user:", error);
            Swal.fire("Error!", "There was an error deleting the user.", "error");
          });
      }
    });
  };

  const filteredUsers = users.filter((user) => {
    const categoryName = user.category?.category || "";
    const title = user.title || "";
    const phone = user.phone || "";
    const address = user.address || "";
    const isPaid = user.isPaid || "";
    const email = user.email || "";
    const description = user.description || "";

    return (
      categoryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      phone.includes(searchQuery) ||
      address.toLowerCase().includes(searchQuery.toLowerCase()) ||
      isPaid.toLowerCase().includes(searchQuery.toLowerCase()) ||
      email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      description.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const isUserLoggedIn = localStorage.getItem("user");
  const scond = JSON.parse(isUserLoggedIn);

  return (
    <main className="content px-3 py-2">

        <div className="container-fluid row d-flex align-items-center">
          <div className="title-bar1 col-md-4">
            <h4>Trainer Management</h4>
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Users List
              </li>
            </ol>
          </div>
          <div className="col-md-6">
            <div className="totle-use">
              <h4>
                Total Users: <b>{filteredUsers.length}</b>
              </h4>
            </div>
          </div>
          <div className="col-md-2">
            <Link to="/dashboard/trainer-add" className="btn btn-success mb-3">
              <IoIosAddCircleOutline /> Add Trainer
            </Link>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table id="example" className="display table table-bordered text-center">
                  <thead className="table-light">
                    <tr>
                      <th>ID</th>
                      <th>Vendor Id</th>
                      <th>Profile</th>
                      <th>Category Name</th>
                      <th>Address</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.length > 0 ? (
                      filteredUsers.map((user, idx) => (
                        <tr key={user._id}>
                          <td>{idx + 1}</td>
                          <td className="VendorMainDashboard">{user._id}</td>
                          <td >
                            {/* Show the image if profileUrl is present */}
                            {user.profileUrl ? (
                              <img className="rounded-circle"
                                src={`https://api.khiladiindia.com/backend/uploads/homepage/${user.profileImageTrainer.split('/').pop()}`}
                                // alt={user.profileUrl} 
                                width="50"
                                height="50"
                                style={{ objectFit: 'cover' }}
                              />
                            ) : (
                              "NA"
                            )}
                          </td>

                          <td>{user.category || "N/A"}</td>
                          <td className="VendorMainDashboard">{user.address || "N/A"}</td>
                          <td>{user.phone || "N/A"}</td>
                          <td>{user.email?.slice(0, 15) || "N/A"}</td>
                          <td>
                            <Link
                              to={`/dashboard/trainer-edit/${user._id}`}
                              className="btn btn-primary me-2 btn-sm"
                            >
                              <CiEdit /> Edit
                            </Link>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() => deleteHandleUser(user._id)}
                            >
                              <MdDeleteOutline /> Delete
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" className="text-center">
                          No users found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) 
    </main>
  );
};

export default TotalTrainers;
